$backgroundImage: url('../images/home/Bg-Dektop-Lista@2x.png'  )

.relleno
    margin-bottom: 20%
    width: 200%
.body_primer_torneo
    margin-top: 2.5%
.above_area_torneo
        display: grid
        margin-top: 2%
        margin-bottom: 5%
        .navbar_above
            grid-row: 1
            grid-column: 1
            display: flex
            justify-content: left
            align-items: center
            margin-left: 3rem
        .central_logo_above
            grid-row: 1
            grid-column: 2
            display: flex
            justify-content: left
            align-items: center
            margin-top: 2%
            margin-left: 20%
            img
                display: flex
                width: 500px 
                margin-top: 1%
                padding-right: 14%
            p
                font-weight: lighter
                color: white
                font-size: 25px
                margin-right: 2%
                b
                    font-weight: 900
                .b_bigger
                    text-decoration: none
                    font-weight: 900
                    font-size: 35px
        .return_above
            grid-row: 1
            grid-column: 3
            display: flex
            justify-content: center
            align-items: center
            margin-top: 2%
            margin-right: 10%
            img
                display: flex
                width: 40px
            a
                text-decoration: none
                p
                    font-weight: bold
                    color: white
                    font-size: 35px
                &:hover
                    p
                        color: #3FB3E3
            
                
                cursor: pointer
.right_area_torneo
    width: 18% 
    float: right
.celda_tabla
    margin-bottom: 2%
    width: 100px
.row_table
    font-size: 20
.footer_carousel
    justify-content: center
    position: relative
    text-align: center
    .terminos_condiciones_img_div_nuevo
        display: grid
        
        .img_tight1
            grid-row: 1
            margin-bottom: 5%
        .img_tight2
            grid-row: 2
            margin-bottom: 5%
        .img_tight3
            grid-row: 3
            margin-bottom: 5%
        .h2_tight
            grid-row: 4
            margin-bottom: 10%
            h2
                color: white
                font-size: 10px
@media only screen and (min-width: 992px) 
    .left_area_torneo
        width: 18% 
        float: left
        padding-right: 5%
    .main_area_torneo
        width: 90% 
        float: left
        box-sizing: border-box
        margin-top: 0%
        margin-bottom: 10%
        margin-left: 6%
        padding-bottom: 5%
        padding-left: 2%
        padding-right: 2%
        padding-top: 2%
        border-radius: 2%
        background-color: #fff
        h1
            color: #002C8D
        a
            padding-left: 20%
        .buscar_button
            margin-left: 5%
            margin-top: 2%
            margin-bottom: 5%
            width: 25%
            border-radius: 25px
            color: white
            background-color: #002361
            font-weight: bold
            font-size: 150%
            &:hover
                background-color: #002361DF
        .buscar_input_div
            width: 100%
            margin-left: 5%
            margin-top: 2%
            .buscar_input
                box-sizing: border-box
                width: 25%
                height: 50px
                padding-left: 2%
                padding-right: 2%
                border-radius: 25px
                border: 1px solid #FFFFFF
                background-color: #FFFFFFAA
        img
            width: 105%
            border-radius: 25px
            margin-left: -2.5%
            margin-top: -2.4%
            margin-bottom: 7%
    
    .h4_title
        color: #002361
        margin-left: 5%
        margin-top: 2%
    .footer_2
        padding-bottom: 0%
        .terminos_condiciones_img_div
            grid-row: 1
            grid-column: 1/4
            width: 40%
            margin-left: 5%
            margin-bottom: 5%
            .img_tight1
                
                margin-right: -12%
            .img_tight2
                margin-right: -8%
        aside
            display: flex
            margin-top: -7%
            margin-right: 10%
            float: right
            h2
                grid-row: 1
                grid-column: 3
                font-size: 10px
                min-width: 500px
                margin: auto
                color: white
@media only screen and (max-width: 992px)

    .above_area_torneo
        margin-top: 10%
        margin-bottom: 10%
        .navbar_above
            margin-left: 0rem
            margin-right: 3rem
        .central_logo_above
            margin-bottom: 2%
            margin-right: 5%
            img
                padding-right: 30%
                width: 15%
            p
                width: 200px
                font-weight: lighter
                color: white
                font-size: 15px
                margin-right: 1%
                b
                    font-weight: 900
                .b_bigger
                    text-decoration: none
                    font-weight: 900
                    font-size: 15px

    .h4_title
        color: #00133B
        text-align: center
        margin-top: 5%

    .main_area_torneo
        h1
            color: #00133B
        width: 90% 
        float: left
        box-sizing: border-box
        margin-top: 0%
        margin-bottom: 10%
        margin-left: 6%
        padding-bottom: 5%
        padding-left: 2%
        padding-right: 2%
        padding-top: 2%
        background-color: #fff

        a
            padding-left: 20%
        .buscar_button
            margin-left: 15%
            margin-top: 5%
            margin-bottom: 5%
            width: 70%
            height: 40px
            border-radius: 25px
            color: white
            background-color: #00133B
            font-weight: bold
            font-size: 100%
            &:hover
                background-color: #00133BBF
        .buscar_input_div
            width: 100%
            margin-left: 15%
            margin-top: 5%
            .buscar_input
                box-sizing: border-box
                width: 70%
                height: 40px
                padding-left: 5%
                border-radius: 25px
                border: 1px solid #FFFFFF
                background-color: #FFFFFFAA
        .logo_torneo
            margin-left: -15%
            margin-top: 10%
            box-sizing: border-box
        img
            width: 105%
            border-radius: 0px
            margin-left: -2.5%
            margin-top: -2.4%
            margin-bottom: 7%

    .footer_2
        .terminos_condiciones_img_div
            margin-bottom: 2%
            margin-left: 12%
            
            .img_tight1
                margin-right: -12%
            
            .img_tight2
                margin-right: -8%

        aside
            text-align: center
            margin-bottom: 5%
            h2
                font-size: 17px
                padding-left: 15%
                padding-right: 15%
                color: white
                font-weight: lighter

@media only screen and (max-height: 500px) 
    .above_area_torneo
        margin-top: 10%
        margin-bottom: 10%

        .navbar_above
            margin-left: 0rem
            margin-right: 3rem
        .central_logo_above
            margin-bottom: 2%
            margin-right: 5%
            img
                width: 15%
                padding-right: 18%
    .footer_2
        .terminos_condiciones_img_div
            margin-left: 35%
            margin-bottom: 2%

        aside
            text-align: center
            margin-bottom: 5%
            h2
                font-size: 17px
                padding-left: 25%
                padding-right: 25%
                color: white
                font-weight: lighter