.uploadBox
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    font-size: 60px
    box-sizing: border-box
    border-radius: 5px
    img
        height: 100%
        border-radius: 5px

.input_file_image
    display: none

.image_name
    
    fontSize: bold
    width:  100%
.btn
    display: flex
    justify-content: center
    position: relative
    top: 20px
    .button_upload
        margin: auto
        color: #fff
        background-color: #021d49df
        cursor: pointer
        &:hover
                background-color: #15294adf
        &:disabled
            color: #ffffff42
//Divs de seccionSs
.carga_banner_div
    margin-top: -4%
    .form_titulo
        h1
            text-align: center
            color: white
            margin-bottom: 4%

    .form_select
        display: flex
        width: 100%
        justify-content: center
        align-items: center
        margin-bottom: 2%
        .MenuItem_torneo
            color: #FFF
    
    .form_update
        display: flex
        width: 100%
        justify-content: center
        align-items: center
        margin-bottom: 4%
        .button_upload
            color: #fff
            background-color: #021d49df
            cursor: pointer
            &:hover
                    background-color: #15294adf
            &:disabled
                color: #ffffff42

    .form_nombre
        display: flex
        width: 100%
        justify-content: center
        align-items: center
        .image_name
            width: 15%
            margin-bottom: 4%
            color: white

    .form_descripcion
        display: flex
        width: 100%
        justify-content: center
        align-items: center
        .image_name
            width: 25%
            margin-bottom: 4%
            color: white

    .form_activado
        display: flex
        width: 100%
        justify-content: center
        align-items: center
        margin-bottom: 4%
        .div_form
            background-color: #ffffff42
            border-radius: 10px
            padding-left: 1%
            padding-right: 1%
            display: flex
            justify-content: center
            align-items: center
            p
                color: white
                    
    .form_excel
        display: flex
        width: 100%
        justify-content: center
        align-items: center
        margin-top: 4%
        margin-bottom: 8%
        .input_texto
            color: #fff
        .button_excel
            background-color: #021d49df
            color: white
            margin-right: 1%
            &:hover
                background-color: #15294adf
            &:disabled
                color: #ffffff42
        p
            color: white

    .form_img
        margin-bottom: 4%
        h2
            text-align: center
            color: white
            margin-bottom: 4%
        .imageContainer form
            width: 400px
            height: 300px
            background-color: #fff
            margin: auto
            border-radius: 5px
            box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3)
            box-sizing: border-box
            margin-bottom: 3rem

    .form_img_banner
        margin-bottom: 4%
        h2
            text-align: center
            color: white
            margin-bottom: 4%
        .imageContainer form
            width: 800px
            height: 300px
            background-color: #fff
            margin: auto
            border-radius: 5px
            box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3)
            box-sizing: border-box
            margin-bottom: 3rem

    .form_submit
        display: flex
        width: 100%
        justify-content: center
        align-items: center
        margin-bottom: 10%
        .button_upload
            margin-right: 4%
            color: #fff
            background-color: #021d49df
            cursor: pointer
            &:hover
                    background-color: #15294adf
            &:disabled
                color: #ffffff42
        .button_delete
            color: #fff
            background-color: #021d49df
            cursor: pointer
            &:hover
                    background-color: #15294adf
            &:disabled
                color: #ffffff42