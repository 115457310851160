.body_carousel_3_sss
  width: 100%
  font-family: sans-serif
  .carousel_div
    position: relative
    display: flex
    grid-column: 2
    justify-content: center
    align-items: center
    width: 100%
    height: 100%

  .carousel_grid
    display: grid

  .div_image_plus2
    grid-row: 1
    width: 398px
    display: grid
    margin-left: 5%
    padding-right: 0%
    
    h3
      color: white
      margin: auto
      margin-top: 5%
      font-weight: lighter
    p
      color: white
      margin: auto
      max-width: 298px
      padding-left: 0%
      padding-right: 0%
      font-size: 1rem
      margin-top: 5%
      font-weight: lighter
    Button
      width: 100%
      margin-top: 10%
      background-color: #3FB3E3
      color: #00133B
      font-size: 13px
      text-transform: none



  .div_image
    grid-row: 1
    max-width: 398px
    display: grid
    padding-left: 0%
    padding-right: 0%
    h3
      color: white
      margin: auto
      margin-top: 5%
      font-weight: lighter
    p
      color: white
      margin: auto
      max-width: 298px
      padding-left: 0%
      padding-right: 0%
      font-size: 1rem
      margin-top: 5%
      font-weight: lighter
    Button
      width: 100%
      max-width: 398px
      margin-top: 10%
      background-color: #3FB3E3
      color: #00133B
      font-size: 13px
      text-transform: none

  .div_image_hidden
    display: none
  
  .carousel_img
    border-radius: 0.5rem
    display: flex
    // box-shadow: 0px 0px 7px #666
    width: 100%
    max-width: 398px
    max-height: 235px
    margin-left: 0%

  .carousel_img_hidden
    display: none

  // .arrow_div
  //   position: absolute
  //   border: 0.2rem solid
  //   border-color: white
  //   border-radius: 100%
  //   width: 2rem
  //   height: 2rem
  //   align-items: center

  .arrow_div_left
    grid-row: 1
    grid-column: 1

  .arrow_div_right
    grid-row: 1
    grid-column: 3

  .arrow
    position: relative
    display: flex
    border-radius: 100%
    width: 2rem
    height: 2rem
    color: white
    filter: drop-shadow(0px 0px 5px #555)

    &:hover
      cursor: pointer
      background-color: #fff5

  .arrow_left
    top: 40%

  .arrow_right
    top: 40%

  .arrow_none
    display: none
    
  .indicators
    display: flex
    position: absolute
    top: 100%
    left: 50%
    transform: translate(-50%, -90%)

  .indicator_button
    background-color: #e53c3c
    height: 0.7rem
    width: 0.7rem
    border-radius: 100%
    border: none
    outline: none
    box-shadow: 0px 0px 8px #555
    margin: 20rem 0rem 0rem 0.8rem
    cursor: pointer

  .indicator_button_inactive
    background-color: white