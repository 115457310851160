.excel_button_file
    margin-left: 20%
    margin-top: 0%
    box-sizing: border-box
    background-color: #ffc300
    border-radius: 25px
    color: #002C8D
    width: 100%
    font-weight: bold
    font-size: 100%
    &:hover
        background-color: #ffc300AF
.excel_button_text
    margin-left: 20%
    margin-top: 0%
    box-sizing: border-box
    background-color: #ffc300
    border-radius: 25px
    color: #002C8D
    width: 100%
    font-weight: bold
    font-size: 100%
    &:hover
        background-color: #ffc300AF
     
.base
    margin-left: 17%
    .div_input_file
        border: 2px solid #ffc300
        border-radius: 10px
        background-color: #ffc300
        width: 30%

        .input_texto
            width: 100%
    .excel_button_file
        margin-left: 8rem
        margin-top: 0%
        box-sizing: border-box
        background-color: #ffc300
        border-radius: 25px
        color: #002C8D
        width: 100%
        font-weight: bold
        font-size: 100%
        &:hover
            background-color: #ffc300AF
    .div_input_text
        border: 2px solid #ffc300
        border-radius: 10px
        background-color: #ffc300
        width: 30%

        .input_texto
            color: #002C8D
            width: 100%
        
    .excel_button_text
        margin-left: 8rem
        margin-top: 0%
        box-sizing: border-box
        background-color: #ffc300
        border-radius: 25px
        color: #002C8D
        width: 100%
        font-weight: bold
        font-size: 100%
        &:hover
            background-color: #ffc300AF