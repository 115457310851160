.carousel_div_large
  position: relative
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%

  .carousel_img
    border-radius: 0.5rem
    display: flex
    justify-content: center
    align-items: center
    // box-shadow: 0px 0px 7px #666
    width: 100%
    height: 100%
    max-width: 1340px
    max-height: 439px
    margin-left: 0%

    &:hover
      cursor: pointer


  .carousel_img_hidden
    display: none

  // .arrow_div
  //   position: absolute
  //   border: 0.2rem solid
  //   border-color: white
  //   border-radius: 100%
  //   width: 2rem
  //   height: 2rem
  //   align-items: center

  .arrow
    position: absolute
    border-radius: 100%
    width: 2rem
    height: 2rem
    color: white
    filter: drop-shadow(0px 0px 5px #555)

    &:hover
      cursor: pointer
      background-color: #fff5

  .arrow_left
    left: 1rem

  .arrow_right
    right: 1rem

  .indicators
    display: flex
    position: absolute
    top: 100%
    left: 45%
    transform: translate(-50%, -90%)

  .indicator_button
    background-color: #3CB4E5
    height: 0.7rem
    width: 0.7rem
    border-radius: 100%
    border: none
    outline: none
    box-shadow: 0px 0px 8px #555
    margin: 20rem 0rem 0rem 0.8rem
    cursor: pointer

  .indicator_button_inactive
    background-color: white

@media only screen and (max-width: 800px)
  .carousel_div_large
    .carousel_img
      width: 90%
      margin-left: 5%
    .indicators
      display: flex
      position: absolute
      top: 100%
      left: 35%
      transform: translate(-50%, -90%)